<template>
  <div ref="swiperWrapper">
    <swiper-container init="false">
      <slot></slot>
    </swiper-container>
    <div class="flex justify-center gap-4" ref="swiperPagination"></div>

    <SwiperLightbox
      @close-lightbox="$emit('close-lightbox')"
      :lightbox-content="lightboxContent"
      :lightbox-id="lightboxId"
    />
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core'
import type { LightboxContent } from '~/types/generated'

const swiperWrapper = ref()
const swiperPagination = ref()

defineProps<{
  lightboxId: number
  lightboxContent: LightboxContent | null
}>()

let swiperEl: any = null

onMounted(() => {
  initHistorySwiper()
  window.addEventListener('resize', initHistorySwiper)
})

const initHistorySwiper = useDebounceFn(() => {
  swiperEl = swiperWrapper.value?.querySelector('swiper-container')
  if (swiperEl) {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
    let gutter = 16
    let viewableSlides = 1.4
    let pl = (9.014084507042254 * vw) / 100 + 1.3873239436619715 * 16
    let pr = pl
    if (window.matchMedia('(min-width: 768px)').matches) {
      gutter = 32
      viewableSlides = 1.3
      pl = 0
    }
    if (window.matchMedia('(min-width: 1024px)').matches) {
      viewableSlides = 1.3
    }
    if (window.matchMedia('(min-width: 1536px)').matches) {
      viewableSlides = 1.3
    }
    if (window.matchMedia('(min-width: 1920px)').matches) {
      viewableSlides = 1.3
    }
    if (window.matchMedia('(min-width: 2560px)').matches) {
      viewableSlides = 1.4
    }
    const swiperParams = {
      spaceBetween: gutter,
      slidesPerView: viewableSlides,
      pagination: {
        el: swiperPagination.value,
        clickable: true,
        bulletClass:
          'size-vw-3-to-4 rounded-full bg-white transition-colors focus:outline-none focus-visible:outline-blue hover:bg-blue/50',
        bulletActiveClass: '!bg-blue',
      },
      slidesOffsetBefore: pl,
      slidesOffsetAfter: pr,
    }
    Object.assign(swiperEl, swiperParams)
    swiperEl.initialize()
  }
}, 100)
</script>
