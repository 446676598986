<template>
  <span
    class="group inline-flex size-vw-8-to-20 items-center justify-center rounded-full bg-dark-blue transition-colors hover:bg-white"
  >
    <IconComponent
      class="w-vw-2.5-to-6 translate-x-vw-0.5-to-1 text-grey transition-colors group-hover:text-dark-blue"
      icon="play"
    />
    <span class="sr-only">play</span>
  </span>
</template>
